import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Column, Row } from "@react-tiny-grid/core"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 2rem;
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
  height: 100%;
`

const Button = styled.span`
  background-color: ${({ background }) => background};
  border-radius: 12px;
  padding: 12px 40px;
  color: ${({ color }) => (color ? color : "white")};
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: transform 0.5s ease-in-out;
  margin-top: 20px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
  }
`

export default function HeroSection() {
  return (
    <>
      <h1 className="head">WHAT IS FORSINT</h1>
      <br />
      <h1 className="sub-head">
        Forsint is the first Native Multi Profile Virtual Humint Solution that
        includes Forensics and OSINT capabilities.
      </h1>
      <br />
      <Row>
        <Column>
          <Container>
            <p className="text">
              FORSINT is the perfect platform to help you create and maintain
              your investigation persona (sock puppets).
            </p>
            <br />
            <p className="text">
              The modular nature of Forsint allow to integrate in one simple
              solution multiple type of capabilities to satisfy analysts
              requirements.
            </p>
            <br />
            <p className="text">
              Our software is developed with the security of the analyst in
              mind, our approach is not on quantity but on safety first because
              we know how easy and sensitive is to not jeopardize your
              undercover investigation that you spent months to build.
            </p>
            <br />
            <p className="text">
              Considering the very different setup of each Intelligence team, we
              do provide custom tailored solutions to adapt and fullfil any
              integration requirement might arise.
            </p>
          </Container>
        </Column>

        <Column>
          <Container>
            <StaticImage
              src="../../images/logo.png"
              loading="eager"
              width={200}
              height={200}
              alt="Product Image"
            />
            <Button
              background="#F9C712"
              color="#1c365e"
              onClick={() => window.HelpWidget("open")}
            >
              Contact Us
            </Button>
          </Container>
        </Column>
      </Row>
      <br /> <br /> <br />
    </>
  )
}
