import { Column, Row } from "@react-tiny-grid/core"
import React from "react"
import { Zoom } from "react-reveal"
import styled from "styled-components"

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffffcc;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.25);
  padding: 25px 15px;
  margin: 5px;
  border-radius: 10px;
  height: 100%;
  text-align: center;
  transition: transform 0.5s ease-in-out;

  & > h2 {
    color: #1c365e;
    font-size: 1.2em;
  }

  &:hover {
    color: #fff;
    background-color: #1c365e;
    transform: scale(1.1);

    & > h2 {
      color: #fff;
    }
  }
`

const useCases = [
  [
    {
      title: "Cyber Crimes",
      text: "No matter the type of cyber crime you are investigating, you should collect the Evidence as soon as possible in the best possible way.",
    },
    {
      title: "Terrorism",
      text: "Although most of the Terrorism related information is used for short-term prevention operations, do not ignore that there might be a trial one day involving that piece of information. In doubt, capture it!",
    },
    {
      title: "Social Media Crimes",
      text: "It's rare to find crimes that do not involve, in one way or another, some social media evidence; having a tool ready to be used when needed to capture them is paramount.",
    },
  ],
  [
    {
      title: "Online Frauds & Defamation",
      text: "Whenever you need to fight for your rights, do not forget to capture any Evidence that might suddenly disappear!",
    },
    {
      title: "Disgruntled Employee",
      text: "It's essential to preserve evidence that might be a key component in a legal case against a disgruntled employee.",
    },
    {
      title: "Cyber Threat Investigations",
      text: "When you investigate the Dark Web or other criminal-related online pages, it's crucial to Take a forensic copy of what you find that might end up in a Case.",
    },
  ],
]

export default function UseCasesSection() {
  return (
    <>
      <h1 className="head">MOST COMMON FORSINT USE CASES</h1>
      <br />
      <h1 className="sub-head">
        You can use Forsint in many different scenarios including the below most
        common ones:
      </h1>
      <br /> <br />
      {useCases.map((rows, rowIndex) => {
        return (
          <Row
            key={`useCases-${rowIndex}`}
            breakpoints={[769]}
            className="h100"
          >
            {rows.map((column, colIndex) => (
              <Column key={`useCases-${rowIndex}-${colIndex}`}>
                <Zoom>
                  <Box>
                    <h2>{column.title}</h2> <br />
                    <p>{column.text}</p>
                  </Box>
                </Zoom>
              </Column>
            ))}
          </Row>
        )
      })}
      <br /> <br /> <br />
    </>
  )
}
