import * as React from "react"
import Call4ActionSection from "../components/HomePageElements/Call4ActionSection"
import HeroSection from "../components/HomePageElements/HeroSection"
import ModulesSection from "../components/HomePageElements/ModulesSection"
import UseCasesSection from "../components/HomePageElements/UseCasesSection"
import UsersSection from "../components/HomePageElements/UsersSection"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />

    <HeroSection />
    <ModulesSection />
    <UsersSection />
    <UseCasesSection />
    <Call4ActionSection />
  </Layout>
)

export default IndexPage
