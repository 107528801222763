import { Column, Row } from "@react-tiny-grid/core"
import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 auto;

  & > div {
    max-width: 500px;
    width: 45%;
    margin-bottom: 20px;
  }

  @media (max-width: 769px) {
    flex-direction: column;

    & > div {
      max-width: 100%;
      width: 100%;
    }
  }
`

const ShadowBox = styled.div`
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 25px;
  background-color: ${({ color }) => color};
  display: flex;
  flex-direction: column;
  align-items: center;

  &.h100 {
    height: 100%;
  }
`

const Button = styled.span`
  background-color: white;
  border: 1px solid #1c365e;
  border-radius: 12px;
  padding: 12px 40px;
  color: #1c365e;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`

export default function ModulesSection() {
  return (
    <>
      <h1 className="head">OUR MODULES</h1>
      <br />
      <h1 className="sub-head">
        Represent the Innovation is Web Intelligence that was missing!
      </h1>
      <br /> <br />
      <Container>
        <ShadowBox color="#fff">
          <h2 className="text-center">FORSINT PERSONA</h2>
          <br />
          <br />
          <p className="text-justify">
            PERSONA is the core module of Forsint that provide the analyst with
            a advanced platform from where to create and maintain his own
            Investigation profiles and conduct online investigations
          </p>
        </ShadowBox>
        <ShadowBox color="#fff">
          <h2 className="text-center">FORSINT FORENSICS</h2>
          <br />
          <br />
          <p className="text-justify">
            FORENSIC is the optional forsint module dedicate at the forensic
            acquisition of each information and intelligence along your
            investigation.You won't need to revisit previously content to
            forensically acquire them.This is done automatically and
            transparently.
          </p>
          <br />
          <br />
          <p className="text-center text-bold">COMING SOON</p>
        </ShadowBox>
      </Container>
      <Container>
        <ShadowBox color="#fff">
          <h2 className="text-center">FORSINT HARVESTER</h2>
          <br />
          <br />
          <p className="text-justify">
            HARVESTER is the optional module of Forsint that automatically
            collect all the needed data during an investigation and make sure
            not only to store them but also automatically export them to 3rd
            party platforms as per the analyst need. The perfect solution to
            stream your Investigation.
          </p>
          <br />
          <br />
          <p className="text-center text-bold">COMING SOON</p>
        </ShadowBox>
        <ShadowBox color="#fff">
          <h2 className="text-center">FORSINT CORRELATOR</h2>
          <br />
          <br />
          <p className="text-justify">
            CORRELATOR is the optional forsint module in charge of correlating
            harvested data using built-in and user custom rules.Flexible enough
            to fullfil any detection, correlation and notification need.
          </p>
          <br />
          <br />
          <p className="text-center text-bold">COMING SOON</p>
        </ShadowBox>
      </Container>
      <Row>
        <Column>
          <ShadowBox className="h100" color="#1c365e">
            <h2 className="text-center text-secondary">Best Value for Money</h2>
            <br />
            <p className="text-justify text-white">
              Our subscription model offers the most advantageous combination of
              cost, quality and sustainability to meet customer requirements.
            </p>
          </ShadowBox>
        </Column>

        <Column>
          <ShadowBox className="h100" color="#1c365e">
            <h2 className="text-center text-secondary">Reliable</h2>
            <br />
            <p className="text-justify text-white">
              Our product is designed and built to offer you the quickest and
              most stable way to acquire online evidence. Every time. Anytime.
            </p>
          </ShadowBox>
        </Column>

        <Column>
          <ShadowBox className="h100" color="#1c365e">
            <h2 className="text-center text-secondary">Innovative</h2>
            <br />
            <p className="text-justify text-white">
              Our product is developed using the latest technologies to provide
              the fastest Cross-Platform solution available in the market,
              including Next-Generation Blockchains for timestamping.
            </p>
          </ShadowBox>
        </Column>
      </Row>
      <br /> <br /> <br />
    </>
  )
}
